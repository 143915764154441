import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'
import i18next from 'i18next'
import { createSession, sendInputData, sendSessionOption, sendKeyContainer, sendCertificate,
		 encryptData, getEncryptedData, deleteSession, sendTokenPath } from '../actions/api'
import { setBase64String, setCertificateInputFile, clearCertificateInputFile,
		 setInputFileForEncrypt, clearInputFileForEncrypt, setEncryptedData, clearEncryptedData, setEncryptCertExpand } from '../actions/localStates'
import { bindActionCreators } from 'redux';

import EncryptOptionsContainer from './EncryptOptionsContainer'
import EncryptFilesContainer from './EncryptFilesContainer'

import GooglePicker from '../components/body/GooglePicker'

import Dropzone from 'react-dropzone';

const dropzoneCertRef = createRef();

class EncryptContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
			uuid: null,
			isLoading: false,
			base64Data: null,
			downloadIsAvailable: true,
			collapseEncryptCertShow: ""
		}

		this.withToken = this.withToken.bind(this)
		this.handleCertOnDrop = this.handleCertOnDrop.bind(this);
	}

	expandEncryptCertDS() {
		this.props.actions.setEncryptCertExpand(this.props.expandedCardsReducer.encryptCertExpanded)
		this.props.expandedCardsReducer.encryptCertExpanded ? this.setState({collapseEncryptCertShow: "card-body collapse show"}) : this.setState({collapseEncryptCertShow: "card-body collapse"})
	}

	buildFileInputSelector(){
		const certInput = document.getElementById('certInputUpload');
		return certInput;
	}

	buildFileWithDSSelector(){
		const fileForEncrypt = document.getElementById('fileForEncryptUpload');
		return fileForEncrypt;
	}

	componentDidMount(){
    	var dropCert = document.getElementById('dropCert');

    	this.certInput = this.buildFileInputSelector();
    	this.fileForEncryp = this.buildFileWithDSSelector();
    	if (!this.props.pkLocalState.isActive) {
			this.cleanUpForm()
        }
        this.props.expandedCardsReducer.encryptCertExpanded ? this.setState({collapseEncryptCertShow: "card-body collapse"}) : this.setState({collapseEncryptCertShow: "card-body collapse show"})

        if (dropCert !== null) {
	        dropCert.addEventListener('dragenter', function(event) {
				dropCert.style.border = "2px solid steelblue";
			}, false);

			dropCert.addEventListener('dragover', function(event) {
				dropCert.style.border = "2px solid steelblue";
			}, false);

			dropCert.addEventListener('dragleave', function(event) {
				dropCert.style.border = "";
			}, false);
		}
	}

	componentDidUpdate(prevProps) {
		var dropCert = document.getElementById('dropCert');

		if (prevProps.fileCertInputSelected !== this.props.defaultEncrypState.fileCertInputSelected) {
			if (dropCert !== null) {
				dropCert.addEventListener('dragenter', function(event) {
					dropCert.style.border = "2px solid steelblue";
				}, false);

				dropCert.addEventListener('dragover', function(event) {
					dropCert.style.border = "2px solid steelblue";
				}, false);

				dropCert.addEventListener('dragleave', function(event) {
					dropCert.style.border = "";
				}, false);
			}
		}
	}

	handleCertInput(e) {
	    e.preventDefault();
	    this.certInput.value = null;
	    this.certInput.click()
	    document.activeElement.blur()
	}

	handleFileForEncryp(e) {
	    e.preventDefault();
	    this.fileForEncryp.value = null;
	    this.fileForEncryp.click()
	    document.activeElement.blur()
	}

	onChangeCertInput(e) {
		if (e.target.files[0] !== undefined && (e.target.files[0].size < 104857601)) {
			this.props.actions.setCertificateInputFile(e.target.files[0])
			this.props.actions.clearEncryptedData()
		}
	}

	onChangeFileForEncryp(e) {
		if (e.target.files[0] !== undefined && (e.target.files[0].size < 104857601)) {
			this.props.actions.setInputFileForEncrypt(e.target.files[0])
			this.props.actions.clearEncryptedData()
		}
	}

	handleCertOnDrop (files) {
		if (files[0] !== undefined && (files[0].size < 104857601)) {
			this.props.actions.setCertificateInputFile(files[0])
			this.props.actions.clearEncryptedData()
		}
	}

	cleanUpCertInput(){
		this.props.actions.clearCertificateInputFile()
		this.props.actions.clearEncryptedData()
	}

	cleanUpFileForEncryp(){
		this.props.actions.clearInputFileForEncrypt()
		this.props.actions.clearEncryptedData()
	}

	cleanUpForm(){
		this.cleanUpCertInput()
		this.cleanUpFileForEncryp()
		this.props.actions.clearEncryptedData()
	}

	withToken(settedToken) {
		this.setState({isLoading: true})
			this.props.actions.createSession(this.props.connectionStatus.baseUrl)
	        	.then((response) => {
					this.setState({uuid: response.ticketUuid})
					console.log(response);
				return this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultEncrypState.fileForEncrypt)
		            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.sendTokenPath(this.props.connectionStatus.baseUrl, this.state.uuid, settedToken))
		            .then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput))
		            .then((response) => {
		            	this.setState({base64Data: response})
	                    console.log(response);
	                return this.props.actions.sendCertificate(this.props.connectionStatus.baseUrl, this.state.uuid, this.state.base64Data)
	                	.then(() => this.props.actions.encryptData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
			            .then(() => this.props.actions.getEncryptedData(this.props.connectionStatus.baseUrl, this.state.uuid))
			           	.then((response) => {
			           		if (response !== null) {
		            			this.props.actions.setEncryptedData(response)
		                    	console.log(response)
		            		}
		            		this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
	                })
	        })
	}

	getBlobData() {
		var _this = this
	    var saveBlob = (function () {
	        var a = document.createElement("a");
	        document.body.appendChild(a);
	        a.style.cssText = "display: none";
	        return function (blob, fileName) {
	        	if (navigator.appVersion.toString().indexOf('.NET') > 0) {
		           window.navigator.msSaveBlob(blob, fileName);
		        } else {
		            var url = window.URL.createObjectURL(blob);
		            a.href = url;
		            a.download = fileName;
		            a.click();
		            window.URL.revokeObjectURL(url);     
		        }
		        window.setTimeout(function () {
			    	_this.setState({downloadIsAvailable: true})
			    }, 1000) 
	        };
	    }());

	    this.setState({downloadIsAvailable: false})
	    saveBlob(this.props.defaultEncrypState.encData, this.props.defaultEncrypState.fileForEncryptName + ".p7e");
	}

	encrypt() {
		let settedToken = {
			keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				settedToken = {"keyStorePath": this.props.pkLocalState.keyContainer}
				this.withToken(settedToken)
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(this.props.connectionStatus.baseUrl)
		        	.then((response) => {
						this.setState({uuid: response.ticketUuid})
						console.log(response);
					return this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultEncrypState.fileForEncrypt)
			            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.sendKeyContainer(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainer))
			            .then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput))
			            .then((response) => {
			            	this.setState({base64Data: response})
		                    console.log(response);
		                return this.props.actions.sendCertificate(this.props.connectionStatus.baseUrl, this.state.uuid, this.state.base64Data)
		                	.then(() => this.props.actions.encryptData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
				            .then(() => this.props.actions.getEncryptedData(this.props.connectionStatus.baseUrl, this.state.uuid))
				           	.then((response) => {
				           		if (response !== null) {
			            			this.props.actions.setEncryptedData(response)
			                    	console.log(response)
			            		}
			            		this.setState({isLoading: false})
			                })
				            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
		                })
		        })
			}
		} else {
			this.withToken(settedToken)
		}
		
    }

	render() {
		return (
            <div className="row" style={{paddingTop: "10px"}}>
            <EncryptOptionsContainer />
				<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
	                <div className="card" style={{"marginBottom": "10px"}}>
						<div className="card-header" onClick={this.expandEncryptCertDS.bind(this)} role="button">
							{i18next.t("enc:recipientCertificate")}
						</div>
	                  	<div className={this.state.collapseEncryptCertShow}>
	                  	<label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 col-form-label no-left-right-padding">{i18next.t("enc:recipientCertificateTitle")}</label>
                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-left-right-padding">
                                {
                                  this.props.mobileModeReducer.mobileDeviceMode
                                  ? 
                                  <div className="input-group row" style={{marginLeft: "0px"}}>
                                    <input type="text" className="form-control col-lg-8 col-md-8 col-xs-12 col-sm-12"value={this.props.defaultEncrypState.certInputName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleCertInput.bind(this)} readOnly />
                                    {
										this.props.defaultEncrypState.fileCertInputSelected
										? <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleCertInput.bind(this)}>{i18next.t("changeFile")}</button>
										: <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleCertInput.bind(this)}>{i18next.t("chooseFile")}</button>
									}
                                    </div>
                                    : <div>
                                    	{
                                    		this.props.defaultEncrypState.fileCertInputSelected
                                    		? <div className="input-group mb-3">
												<input type="text" className="form-control" value={this.props.defaultEncrypState.certInputName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleCertInput.bind(this)} readOnly />
	                                    		<div className="input-group-append">
											      	<button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.cleanUpCertInput.bind(this)}>{i18next.t("cleanup")}</button>
											    </div>
											</div>
										    : <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{marginLeft: "0px", marginRight: "0px"}}>
						                    	{
						                    		this.props.base.googleDriveEnabled === "true"
						                    		? <div className="row">
						                    			<div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 padding-left-0-gdrive">
						                    				<Dropzone ref={dropzoneCertRef} onDrop={this.handleCertOnDrop} multiple={false}>
										                        {({getRootProps, getInputProps}) => (
										                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone col-lg-12 col-md-12 col-xs-12 col-sm-12" id="dropCert">
										                                <input {...getInputProps()} />
										                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitleSecond")}</p>
										                            </div>
										                        )}
										                    </Dropzone>
									                    </div>
									                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 no-left-padding-gdrive padding-left-0-gdrive">
															<GooglePicker caller={"crtFile"} />
														</div>
						                    		</div>
						                    		: <Dropzone ref={dropzoneCertRef} onDrop={this.handleCertOnDrop} multiple={false}>
								                        {({getRootProps, getInputProps}) => (
								                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone" id="dropCert">
								                                <input {...getInputProps()} />
								                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitleSecond")}</p>
								                            </div>
								                        )}
								                    </Dropzone>
								                }
														
								            </div>
                                    	}
									</div>
                                }
                                <input id="certInputUpload" type="file" onChange={this.onChangeCertInput.bind(this)} style={{"display": "none"}} />
                            </div>
	                    </div>
	                </div>
	                <EncryptFilesContainer />
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.encryptOptionsReducer,
        defaultEncrypState: state.encryptReducer,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendSessionOption,
	    sendKeyContainer,
	    setBase64String,
	    sendCertificate,
	    encryptData,
	    getEncryptedData,
	    deleteSession,
	    setCertificateInputFile,
	    clearCertificateInputFile,
	    setInputFileForEncrypt,
	    clearInputFileForEncrypt,
	    setEncryptedData,
	    clearEncryptedData,
	    sendTokenPath,
	    setEncryptCertExpand
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EncryptContainer);